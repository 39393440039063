import styles from "./styles.module.scss";
import { FC } from "react";
import { TRatingLine } from "./types.ts";

const RatingLine: FC<TRatingLine> = ({ number, ratingChild = 0 }) => {
    return (
        <div className={styles.wrapper}>
            <p className={styles.number}>{number}</p>
            <div className={styles.block}>
                <div
                    className={styles.child}
                    style={{ width: `${ratingChild}%` }}
                ></div>
            </div>
        </div>
    );
};

export default RatingLine;
